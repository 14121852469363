<template>
  <div class="process-details-content">
    <div class="container">
      <div class="row full-heigth">
        <!-- INICIO Detalhes do processo -->
        <div class="col-md-3 text-left dark-box">

          <div class="row" >
            <div class="row col-12 mb-5">
              <div  class="row col-12 mb-3">
                <div class="col-12 text-muted">Código:</div>
                <div class="col-12 ">{{ person.codigo}}</div>
              </div>
              <div v-if="person.nome" class="row col-12 mb-3">
                <div class="col-12 text-muted">Nome</div>
                <div class="col-12 ">{{ person.nome }}</div>
              </div>
              <div v-if="person.tipo_pessoa == true" class="row col-12 mb-3">
                <div class="col-12 text-muted">CNPJ</div>
                <div class="col-12 ">{{ person.cpf_cnpj }}</div>
              </div>
              <div v-else class="row col-12 mb-3">
                <div class="col-12 text-muted">CPF</div>
                <div class="col-12 ">{{ person.cpf_cnpj }}</div>
              </div>
              <div v-if="person.tipo_pessoa == true && person.nome_fantasia" class="row col-12 mb-3">
                <div class="col-12 text-muted">Nome Fantasia</div>
                <div class="col-12 ">{{ person.nome_fantasia }}</div>
              </div>
            </div>

            <div class="row col-12 mb-5">
              <div v-if="person.fone" class="row col-12 mb-3">
                <div class="col-12 text-muted">Fone</div>
                <div class="col-12 ">{{ person.fone }}</div>
              </div>
              <div v-if="person.fax_celular" class="row col-12 mb-3">
                <div class="col-12 text-muted">Fax/Celular</div>
                <div class="col-12 ">{{ person.fax_celular }}</div>
              </div>

              <div v-if="person.email" class="row col-12 mb-3">
                <div class="col-12 text-muted">E-mail</div>
                <div class="col-12 ">{{ person.email }}</div>
              </div>

            </div>
          </div>
        </div>
        <!-- FIM Detalhes do processo -->
        <!-- Inicio timeline-->
        <div class="col-md-9">
          <div class="close-button mb-4">
            <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
              <span><i class="text-primary rc-Ativo-36"> </i></span>
            </div>
          </div>
          <ul class="nav line-tabs borderless flex-center mb-5">

            <li class="nav-item">
              <a class="nav-link"  data-toggle="tab" >Endereço</a>
            </li>
          </ul>
          <!-- Endereço -->
          <div>
            <div class="row">
              <div class="col-2">
                <custom-input
                  v-model="person.cep"
                  name="person.cep"
                  label="CEP"
                  type="text"
                  :readonly="true"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  :error="errors.first('person.cep')">
                </custom-input>
              </div>
              <div class="col-8">
                <custom-input
                  v-model="person.logradouro"
                  name="person.logradouro"
                  label="Logradouro"
                  type="text"
                  :readonly="true"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  :error="errors.first('person.logradouro')">
                </custom-input>
              </div>
              <div class="col-2">
                <custom-input
                  v-model="person.numero"
                  name="person.numero"
                  label="Número"
                  type="text"
                  :readonly="true"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  :error="errors.first('person.numero')">
                </custom-input>
              </div>
              <div class="col-4">
                <custom-input
                  v-model="person.complemento"
                  name="person.complemento"
                  label="Complemento"
                  type="text"
                  :readonly="true"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  :error="errors.first('person.complemento')">
                </custom-input>
              </div>
              <div class="col-1">
                <custom-input
                  :value="(person.idunidade_federativa) ? person.idunidade_federativa.sigla : null"
                  name="person.idunidade_federativa.sigla"
                  label="UF"
                  type="text"
                  :readonly="true"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  :error="errors.first('person.idunidade_federativa.sigla')">
                </custom-input>
              </div>
              <div class="col-4">
                <custom-input
                  :value="(person.idmunicipio) ? person.idmunicipio.nome : null"
                  name="person.idmunicipio"
                  label="Município"
                  type="text"
                  :readonly="true"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  :error="errors.first('person.idmunicipio.nome')">
                </custom-input>
              </div>

              <div class="col-4">
                <custom-input
                  v-model="person.bairro"
                  name="person.bairro"
                  label="Bairro"
                  type="text"
                  :readonly="true"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  :error="errors.first('person.bairro')">
                </custom-input>
              </div>

              <div class="col-4">
                <custom-input
                  :value="(person.idpais) ? person.idpais.nome : null"
                  name="person.idpais.nome"
                  label="Pais"
                  type="text"
                  :readonly="true"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  :error="errors.first('person.idpais.nome')">
                </custom-input>
              </div>
            </div>
          <div>
          </div>
          </div>
          <!-- FIM Endereço -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProcessService from '@/services/Process/ProcessService'

// Components
import CustomInput from '@/components/Forms/CustomInput.vue'

export default {
  name: 'PersonInfoView',
  props: {
    person: {
      type: Object,
      required: true
    }
  },
  components: {
    CustomInput
  },
  methods: {
    onClose () {
      this.$emit('close')
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    fileDownload (idProcess, idFile, fileName) {
      this.isLoading = true
      ProcessService.getProcessFile(idProcess, idFile).then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        global.document.body.appendChild(link)
        link.click()
      })
    }
  },
  computed: {
    getPais () {
      if (this.person.idpais) {
        return this.person.idpais.nome
      }

      return null
    }
  }
}
</script>

<style scoped>

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }

  tr.internal {
    background-color: #fcf8e3;
  }

</style>
