import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import httpBuildQuery from 'http-build-query'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const MovementTypeService = {
  getMovementTypes (filter, page, itemsPerPage) {
    let queryString = httpBuildQuery(filter)
    page = page || 1
    itemsPerPage = itemsPerPage || 1000

    filter.page = page
    filter.per_page = itemsPerPage

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/tipos-movimentos?${queryString}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getMovementType (idMovementType) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/tipos-movimentos/${idMovementType}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  postMovementType (dataContent) {
    try {
      const movement = dataContent
      movement.taxes.map(tax => {
        delete tax.idtaxa_logistica_exibicao
        return tax
      })
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/tipos-movimentos`, movement).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  putMovementType (idMovementType, dataContent) {
    try {
      const movement = dataContent
      movement.taxes.map(tax => {
        delete tax.idtaxa_logistica_exibicao
        return tax
      })
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/tipos-movimentos/${idMovementType}`, movement).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  delete (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.delete(`${urlAPI}v2/tipos-movimentos/${id}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default MovementTypeService
