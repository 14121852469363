<template>
  <div>
    <form>
      <loading :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="fullPage"></loading>
      <div class="row justify-content-md-center">
        <div class="col-12 col-sm-12">
          <div class="card">
            <div class="card-body">
              <table class="table align-middle table-clickable">
                <thead>
                  <tr>
                    <th>{{this.$i18n.t('form.invoices.internal_code')}}</th>
                    <th>{{this.$i18n.t('form.invoices.customer_reference')}}</th>
                    <th>{{this.$i18n.t('form.invoices.customer')}}</th>
                    <th>{{this.$i18n.t('form.movementType.variableField.takerName')}}</th>
                    <th>{{this.$i18n.t('form.invoices.customer_invoice')}} </th>
                    <th>{{this.$i18n.t('form.invoices.movement_type')}}</th>
                    <th class="text-right">{{this.$i18n.t('form.invoices.amount_real')}}</th>
                    <th class="text-right">{{this.$i18n.t('form.invoices.actions')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, index) in invoices">
                    <tr :key="index">
                      <td>{{item.numeroProcesso}}</td>
                      <td>{{item.referenciaCliente}}</td>
                      <td>
                        <div v-on:click="showPersonData(item.idpessoa)" >
                          {{item.cliente}} ({{(item.clienteCpfCnpj) ? item.clienteCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                        </div>
                      </td>
                      <td style="vert-align: middle; min-width: 250px">
                        <div v-if="item.pessoaPadrao.id == 1 && item.idpessoa" v-on:click="showPersonData(item.idpessoa)" >
                          {{item.cliente}} ({{(item.clienteCpfCnpj) ? item.clienteCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                        </div>
                        <div v-else-if="item.pessoaPadrao.id == 2 && item.idNotify" v-on:click="showPersonData(item.idNotify)">
                          {{item.notify}} ({{(item.notifyCpfCnpj) ? item.notifyCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                        </div>
                        <div v-else-if="item.pessoaPadrao.id == 3 && item.idImportador" v-on:click="showPersonData(item.idImportador)">
                          {{item.importador}} ({{(item.importadorCpfCnpj) ? item.importadorCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                        </div>
                        <div v-else-if="item.pessoaPadrao.id == 4 && item.idExportador" v-on:click="showPersonData(item.idExportador)">
                          {{item.exportador}} ({{(item.exportadorCpfCnpj) ? item.exportadorCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                        </div>
                        <div v-else-if="item.pessoaPadrao.id == 5 && item.idAgenteOrigem" v-on:click="showPersonData(item.idAgenteOrigem)">
                          {{item.agenteOrigem}} ({{(item.agenteOrigemCpfCnpj) ? item.agenteOrigemCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                        </div>
                        <div v-else-if="item.pessoaPadrao.id == 6  && item.agenteDestino" v-on:click="showPersonData(item.idAgenteDestino)" >
                          {{item.agenteDestino}} ({{(item.agenteDestinoCpfCnpj) ? item.agenteDestinoCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                        </div>
                        <div v-else-if="item.pessoaPadrao.id == 7 && item.idDespachanteAduaneiro" v-on:click="showPersonData(item.idDespachanteAduaneiro)">
                          {{item.despachanteAduaneiro}} ({{(item.despachanteAduaneiroCpfCnpj) ? item.despachanteAduaneiroCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                        </div>
                        <!-- idOutro -->
                        <div v-else-if="item.pessoaPadrao.id == 8">
                          <v-select :placeholder="$t('filter.enter_text')"   class="md-form-control" v-model="item.idOutro" label="nome" :clearable="false" :options="personList" @search="personFetchOptions" >
                            <template slot="no-options">
                              {{ $t('Nenhum resultado encontrado') }}
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option['nome'] }}
                                <br/> {{ option['cpf_cnpj'] ? '(' + option['cpf_cnpj'] + ')' : '' }}
                              </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                              <div class="selected d-center">
                                {{ option['nome'] }} <i v-on:click="showPersonData(item.idOutro.id)" class="pl-1 pull-right fa fa-info-circle"></i>
                                <br/> {{ option['cpf_cnpj'] ? '(' + option['cpf_cnpj'] + ')' : '' }}
                              </div>
                            </template>
                          </v-select>
                        </div>
                        <div v-else>
                          N/A
                        </div>
                      </td>
                      <td>
                        <v-select
                          label="nome"
                          class="md-form-control"
                          :clearable="false"
                          :searchable="true"
                          :options="personTypeList"
                          v-model="item.pessoaPadrao"
                        >
                          <template slot="no-options">
                            {{ 'Nenhum resultado encontrado' }}
                          </template>
                          <template slot="option" slot-scope="option">
                            <div class="d-center">
                              {{ option.nome }}
                            </div>
                          </template>
                          <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                              {{ option.nome }}
                            </div>
                          </template>
                        </v-select>
                      </td>
                      <td>
                        <v-select
                        label="name"
                        class="md-form-control"
                        :clearable="false"
                        :searchable="true"
                        :options="movementTypeList"
                        @input="onChangeMovementType(item)"
                        v-model="item.tipoMovimentacaoPadrao">
                        <template slot="no-options">
                          {{ 'Nenhum resultado encontrado' }}
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.name }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.name }}
                          </div>
                        </template>
                      </v-select>
                      </td>
                      <td class="text-right">{{$util.formatNumber(item.valorTotal)}}</td>
                      <td class="text-right">
                        <div class="custom-actions">
                          <a class="btn btn-link btn-padding" :title="$t('actions.delete')"
                            v-on:click="onDelete(index)">
                            <i class="text-primary  ti-trash font-20"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr :key="`${index}-detailrow`">
<!--                      <td colspan="7">-->
<!--                        <div>-->
<!--                          <div class="mb-2 mt-2">-->
<!--                          <strong>Taxas:  <i  v-on:click="showTaxesData(item)" class="fa fa-info-circle"></i></strong>-->
<!--                          </div>-->

<!--                        </div>-->
<!--                      </td>-->
<!--                      <td></td>-->
                    </tr>
                   </template>
                </tbody>
                <tfoot>
<!--                  <tr>-->
<!--                    <td colspan="6" class="text-right"><strong>Imposto de Renda:</strong></td>-->
<!--                    <td class="text-right">-->
<!--                      {{$util.formatNumber(totalIR)}}-->
<!--                    </td>-->
<!--                    <td></td>-->
<!--                  </tr>-->
                  <tr>
                    <td colspan="6" class="text-right"><strong>Total:</strong></td>
                    <td class="text-right">
                      {{$util.formatNumber(totalInvoice)}}
                    </td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12">
          <div class="btn-group text-right pull-right" role="group" aria-label="Geração de Nota Fiscal">
            <button :disabled="isSubmiting" type="button" v-on:click="btnAgroup" class="btn btn-outline-light">
              {{ $t('actions.agroup') }}
            </button>
<!--            <button :disabled="isSubmiting" type="button" v-on:click="btnTransmit" class="btn btn-outline-light">-->
<!--              {{ $t('actions.transmit') }}-->
<!--            </button>-->
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import _ from 'lodash'
import Loading from 'vue-loading-overlay'

// Get services
import MovementTypeService from '@/services/MovementTypeService'
import PersonTypeService from '@/services/PersonTypeService'

import TaxLogisticService from '@/services/TaxLogisticService'
import BillService from '@/services/BillService'

// import Multiselect from 'vue-multiselect'
// import 'vue-multiselect/dist/vue-multiselect.min.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import PersonService from '../../../services/PersonService'

import PersonInfoView from './PersonInfoView'
import TaxesInfoView from './TaxesInfoView'
import Vue from 'vue'
import InvoiceService from '../../../services/InvoiceService'

export default {
  name: 'InvoiceManager',
  components: {
    Loading,
    // Multiselect,
    vSelect
  },
  data () {
    return {
      personList: [],
      personTypeList: [],
      movementTypeList: [],
      idTaxes: [],
      taxesList: [],
      totalIR: 0,
      totalInvoice: 0,
      isSubmiting: false,
      isLoading: true,
      fullPage: false
    }
  },
  computed: {
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    invoices () {
      return this.$store.getters.getInvoiceToManager
    }
  },
  mounted () {
    let _this = this
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)

    if (this.invoices.length <= 0) {
      this.$router.push({ name: 'InvoiceListInternationalBill' })
    }

    // Selecionando taxas selecionadas iniciais de acordo com o tipo de movimentação
    this.invoices.map(invoice => {
      invoice.taxasSelecionadas = invoice.tipoMovimentacaoPadrao.taxes
    })

    this.movementTypeFetch()
    this.personTypesFetch()
    this.personFetch()
    this.getAllTaxes()
    this.calcTotalAmount()

    for (let index in this.invoices) {
      let invoice = this.invoices[index]

      if (invoice.PessoaPadraoID === 8) {
        InvoiceService.getTaker(invoice.idOutro).then(res => {
          _this.invoices[index].idOutro = res.data
        })
      }
    }

    this.isLoading = false
  },
  methods: {
    getTakerId (invoice) {
      if (invoice.pessoaPadrao.id === 1) {
        return invoice.idpessoa
      } else if (invoice.pessoaPadrao.id === 2) {
        return invoice.idNotify
      } else if (invoice.pessoaPadrao.id === 3) {
        return invoice.idImportador
      } else if (invoice.pessoaPadrao.id === 4) {
        return invoice.idExportador
      } else if (invoice.pessoaPadrao.id === 5) {
        return invoice.idAgenteOrigem
      } else if (invoice.pessoaPadrao.id === 6) {
        return invoice.agenteDestino
      } else if (invoice.pessoaPadrao.id === 7) {
        return invoice.idDespachanteAduaneiro
      } else if (invoice.pessoaPadrao.id === 8) {
        return invoice.idpessoa
      }
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    personFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      InvoiceService.getListTakersAvailable(filters).then(res => {
        _this.personList = res.data.data
      }).finally(() => {
        loading(false)
      })
    },
    showTaxesData (invoice) {
      let _this = this
      var ComponentClass = Vue.extend(TaxesInfoView)
      var TaxesInfoViewInstance = new ComponentClass({
        data: {
          isSuperAdmin: _this.isSuperAdmin,
          invoice: invoice
        }
      })
      TaxesInfoViewInstance.$mount()
      let TaxesInfoViewContent = TaxesInfoViewInstance.$el
      global.instanceApp.$alertSwal.modalAlert(null, TaxesInfoViewContent, null, 'no-padding', { showConfirmButton: false }).then((result) => {
      })
    },
    showPersonData (idPerson) {
      let _this = this
      _this.isLoading = true

      PersonService.getPerson(idPerson).then(res => {
        var ComponentClass = Vue.extend(PersonInfoView)
        var PersonInfoViewInstance = new ComponentClass({
          data: {
            isSuperAdmin: _this.isSuperAdmin,
            person: res.data.data
          }
        })
        PersonInfoViewInstance.$mount()
        let PersonInfoViewContent = PersonInfoViewInstance.$el
        global.instanceApp.$alertSwal.modalAlert(null, PersonInfoViewContent, null, 'no-padding', { showConfirmButton: false }).then((result) => {
        })

        _this.isLoading = false
      })
    },
    onDelete (index) {
      this.$delete(this.invoices, index)
      this.calcTotalAmount()
    },
    calcTotalAmount () {
      this.totalIR = 0.0
      this.totalInvoice = 0.0
      this.invoices.map(invoice => {
        invoice.valorIRTotal = 0.0
        // invoice.valorTotal = invoice.lucro_bruto_momento
        // if (invoice.taxasSelecionadas) {
        //   invoice.taxasSelecionadas.map(taxaPadrao => {
        //     invoice.taxas.filter(taxaInvoice => parseInt(taxaInvoice['idtaxa_logistica_exibicao']) === parseInt(taxaPadrao['idtaxa_logistica_exibicao'])).map(valores => {
        //       invoice.valorTotal = parseFloat(invoice.valorTotal) - parseFloat(valores.valortotal)
        //     })
        //   })
        // }
        // this.totalIR = invoice.valorIRTotal
        this.totalInvoice += invoice.valorTotal
      })
    },
    onChangeMovementType (value) {
      value.taxasSelecionadas = value.tipoMovimentacaoPadrao.taxes
      this.calcTotalAmount()
    },
    onChangeTaxes (value) {
      this.calcTotalAmount()
    },
    filterTaxesMovementType (item, value) {
      const taxasInvoice = item.taxas || []
      const taxasMovementType = item.tipoMovimentacaoPadrao ? item.tipoMovimentacaoPadrao.taxes : []
      if (taxasInvoice && taxasMovementType) {
        const result = taxasInvoice.filter(taxa => _.findIndex(taxasMovementType, { idtaxa_logistica_exibicao: taxa.idtaxa_logistica_exibicao }) >= 0)
        return result
      }
      return taxasInvoice
    },
    movementTypeFetch () {
      MovementTypeService.getMovementTypes({ type: 2 }, 1, 1000).then(res => {
        this.movementTypeList = res.data.data
      })
    },
    personTypesFetch () {
      PersonTypeService.getPersonTypes({}, 1, 1000).then(res => {
        this.personTypeList = res.data.data
      })
    },
    personFetch () {
      let _this = this
      InvoiceService.getListTakersAvailable({}, 1, 20).then(res => {
        _this.personList = res.data.data
      })
    },
    getAllTaxes () {
      TaxLogisticService.getTaxs({}, 1, 1000).then(res => {
        this.taxesList = res.data.data
      })
    },
    // eslint-disable-next-line camelcase
    customLabel ({ idtaxa_logistica_exibicao, nome }) {
      // eslint-disable-next-line camelcase
      return `${idtaxa_logistica_exibicao} – ${nome}`
    },
    btnAgroup () {
      // Taxas devem ser iguais
      let taxes = ''
      let customer = ''
      let personDefault = ''
      let movementType = ''
      let taker = ''
      this.invoices.map(invoice => {
        if (personDefault === '' && movementType === '' && customer === '' && taxes === '') {
          customer = invoice.idpessoa
          personDefault = invoice.pessoaPadrao.id
          taker = this.getTakerId(invoice)
          movementType = invoice.tipoMovimentacaoPadrao.id
          taxes = invoice.taxasSelecionadas
        }

        if (this.getTakerId(invoice) !== taker) {
          taker = false
        }

        if (invoice.idpessoa !== customer) {
          customer = false
        }
        if (invoice.pessoaPadrao.id !== personDefault) {
          personDefault = false
        }
        if (invoice.tipoMovimentacaoPadrao.id !== movementType) {
          movementType = false
        }
        if (!_.isEqual(invoice.taxasSelecionadas, taxes)) {
          taxes = false
        }
      })

      if (personDefault === false) {
        this.agroup = false
        this.$alertSwal.toast('Não foi possível agrupar <strong>Pessoa da Fatura</strong> deve ser igual.', 'error')
        return false
      } else if (taker === false) {
        this.agroup = false
        this.$alertSwal.toast('Não foi possível agrupar <strong>Tomador da nota</strong> deve ser igual.', 'error')
        return false
      } else if (movementType === false) {
        this.agroup = false
        this.$alertSwal.toast('Não foi possível agrupar <strong>Tipo de Movimentação</strong> deve ser igual.', 'error')
        return false
      } else if (taxes === false) {
        this.agroup = false
        this.$alertSwal.toast('Não foi possível agrupar <strong>Taxas</strong> deve ser igual.', 'error')
        return false
      } else {
        this.agroup = true
      }

      this.$alertSwal.modalConfirm(
        '',
        'Você tem certeza de que deseja agrupar todas notas fiscais.<br />Está certo desta ação?',
        'warning', 'Sim', 'Não',
        (e) => this.generateInvoice()
      )
    },
    btnTransmit () {
      this.agroup = false
      this.$alertSwal.modalConfirm(
        '',
        'Você tem certeza de que deseja transmitir todas notas fiscais.<br />Está certo desta ação?',
        'warning', 'Sim', 'Não',
        (e) => this.generateInvoice()
      )
    },
    generateInvoice () {
      try {
        this.isSubmiting = true
        let data = []
        const _this = this

        this.invoices.map(invoice => {
          let idsTaxasSelecionadas = invoice.taxasSelecionadas.map(taxaSelecionada => {
            return parseInt(taxaSelecionada.idtaxa_logistica_exibicao || taxaSelecionada.idtaxaLogisticaExibicao)
          })

          if (idsTaxasSelecionadas.length < 1) {
            throw new Error('Você deve selecionar as taxas.')
          }

          let taxas = invoice.taxas.filter(function (taxa) {
            return idsTaxasSelecionadas.includes(parseInt(taxa['idtaxa_logistica_exibicao']))
          })

          data.push({
            internacional: true,
            pessoaPadraoIdSelected: invoice.pessoaPadrao.id,
            movementTypeIdSelected: invoice.tipoMovimentacaoPadrao.id_movement_type,
            idAgenteDestino: invoice.idAgenteDestino,
            idAgenteOrigem: invoice.idAgenteOrigem,
            idDespachanteAduaneiro: invoice.idDespachanteAduaneiro,
            idExportador: invoice.idExportador,
            idImportador: invoice.idImportador,
            idOutro: (invoice.idOutro) ? invoice.idOutro.id : null,
            idNotify: invoice.idNotify,
            idPessoa: invoice.idpessoa,
            lucroBrutoMomento: invoice.lucro_bruto_momento,
            idregistroFinanceiro: invoice.idregistroFinanceiro,
            numeroProcesso: invoice.numeroProcesso,
            referenciaCliente: invoice.referenciaCliente,
            referenciaExterna: invoice.referenciaExterna,
            numeroProcessoMaster: invoice.numeroProcessoMaster,
            hbl: invoice.hbl,
            modalidadeProcesso: invoice.modalidadeProcesso,
            tipoOperacao: invoice.tipoOperacao,
            totalAmount: invoice.totalAmount,
            taxes: taxas

          })
        })
        _this.isLoading = true

        BillService.postBill({ invoices: data, agroup: this.agroup }).then(result => {
          _this.isLoading = false
          this.$alertSwal.toast('Geração de nota fiscal realizada com sucesso!')
          setTimeout(() => {
            this.$router.push({ name: 'InvoiceListIssuedInternational' })
          }, 1000)
          this.isSubmiting = false
        }).catch(err => {
          _this.isLoading = false
          _this.$alertSwal.toast(err.message, 'error')
          this.isSubmiting = false
        })
      } catch (err) {
        this.isLoading = false
        this.$alertSwal.toast(err.message, 'error')
        this.isSubmiting = false
      }
    }
  }
}
</script>

<style>

</style>
