import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import httpBuildQuery from 'http-build-query'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const TaxLogisticService = {
  getTaxas (filter, page, itemsPerPage) {
    page = page || 1
    itemsPerPage = itemsPerPage || 1000

    filter.page = page
    filter.per_page = itemsPerPage

    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/taxa-logistica?${queryString}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  // Integração Taxa
  postTaxa (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/integration/maersk/tax`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getTaxa (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/integration/${id}/maersk/tax`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            reject(err)
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  deleteTaxa (id) {
    // Todo validate form data
    try {
      return new Promise(function (resolve, reject) {
        Http.delete(`${urlAPI}v2/integration/${id}/maersk/tax`).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data, httpCode: res.status })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  putTaxa (id, data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/integration/${id}/maersk/tax`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  // Fim  Integração Taxa
  getTaxs (filter, page, itemsPerPage) {
    page = page || 1
    itemsPerPage = itemsPerPage || 1000

    filter.page = page
    filter.per_page = itemsPerPage

    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/taxa-logistica-exibicao?${queryString}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getTax (idInvoice) {}
}

export default TaxLogisticService
