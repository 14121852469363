import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import httpBuildQuery from 'http-build-query'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const BillService = {
  getBills (filter, page, itemsPerPage) {
    filter.page = page
    filter.per_page = itemsPerPage

    let queryString = httpBuildQuery(filter)
    page = page || 1
    itemsPerPage = itemsPerPage || 5
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/bill?${queryString}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getStatusExternal (idBill) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/nfse/${idBill}/invoice/external`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  download (idBill, type) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/nfse/${idBill}/download/${type}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getBillFollowUps (idBill) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/bill/${idBill}/events`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getBill (idBill) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/nfse/${idBill}/invoice/internal`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getBillFile (idBill, type) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/bill/${idBill}/file/${type}`, {
          responseType: 'blob'
        }).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  postBill (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/bill`, data)
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
            reject(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  cancelBill (idBill) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/bill/${idBill}/cancel`, {}).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  refreshStatus (idBill) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/bill/${idBill}/refresh`, { force: true })
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default BillService
